import axios from "axios";

// Create an Axios instance with base URL and default headers
const api = axios.create({
  baseURL: "http://localhost:8080/api/tickets",
  headers: {
    "Content-Type": "application/json",
  },
});

// // Function to save configuration
// export const saveConfig = async (configData) => {
//   const requestUrl = "/addConfig";
//   console.log(
//     `Saving Configuration - Axios Request URL: ${api.defaults.baseURL}${requestUrl}`
//   );

//   try {
//     const response = await api.post(requestUrl, configData);
//     console.log("Configuration Saved Response:", response.data);
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       console.error(
//         "Error Response while saving configuration:",
//         error.response.data
//       );
//       console.error("Status Code:", error.response.status);
//     } else if (error.request) {
//       console.error(
//         "No Response Received while saving configuration:",
//         error.request
//       );
//     } else {
//       console.error("Error Message while saving configuration:", error.message);
//     }
//     throw error;
//   }
// };

// // Function to handle useType request
// export const postUseType = async (useType) => {
//   const requestUrl = "/useType";
//   console.log(
//     `Sending useType - Axios Request URL: ${api.defaults.baseURL}${requestUrl}`
//   );

//   try {
//     const response = await api.post(requestUrl, { useType });
//     console.log("useType Response:", response.data);
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       console.error(
//         "Error Response while sending useType:",
//         error.response.data
//       );
//       console.error("Status Code:", error.response.status);
//     } else if (error.request) {
//       console.error(
//         "No Response Received while sending useType:",
//         error.request
//       );
//     } else {
//       console.error("Error Message while sending useType:", error.message);
//     }
//     throw error;
//   }
// };

export default api;
